import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { computed, action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { observes } from '@ember-decorators/object';
export default class Application extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('chatroom') chatService;

  queryParams = ['chat'];
  chat: string = null;
  chatrooms: object[] = [];

  currentChatroom?: any = null;
  nullValue = null;

  constructor() {
    super(...arguments);
    this.headerElement = document.getElementById('header-destination');
  }

  @computed('currentChatroom')
  get currentTargetRoute() {
    if (this.get('currentChatroom')) {
      return 'chat';
    } else {
      return 'index';
    }
  }

  @action
  unsetCurrentRoom() {
    if (this.get('chatService.activeModel') === null) {
      this.transitionToRoute('index');
    } else {
      this.set('chatService.activeModel', null);
      //this.set("chat", null);
      this.transitionToRoute('chat');
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    application: Application;
  }
}

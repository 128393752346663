import Controller from '@ember/controller';

import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';

export default class Fragebogen extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  @task
  *timeout() {
    yield timeout(1000);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'fragebogen': Fragebogen;
  }
}

import Component from '@ember/component';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';

@classNames('app-info-item')
export default class AppInfomItem extends Component.extend({
  // anything which *must* be merged to prototype here
}) {

  @service isMobile;


  constructor() {
    super(...arguments);
  }

  @task
  *download(path) {
    let file_path = path;
    let a = document.createElement('A');
    a.href = file_path;
    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
    a.target="_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  @task
  *youtube(path) {
    let file_path = `https://www.youtube.com/watch?v=${path}`;
    window.open(file_path, "_blank");
  }
  @task
  *link(path) {
    let file_path = `${path}`;
    window.open(file_path, "_blank");
  }

};

import { helper } from "@ember/component/helper";

export function split(params: any) {
  if (params[0] !== undefined && params[0] !== null && typeof params[0] == 'string') {
    return params[0].split(params[1]);
  } else {
    return "";
  }
}

export default helper(split);

import Component from '@ember/component';
import { USER_FRAGMENT } from 'event-components/gql/fragments';
import { inject as service } from '@ember/service';
import { observes } from '@ember-decorators/object';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import gql from 'graphql-tag';
import Bloodhound from 'bloodhound-js';
const query = gql`
  query($where: UserWhereInput!) {
    users(
      where: $where
    ) {
      ${USER_FRAGMENT}
    }
  }
`;

export default class AppChatProfiles extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('chatroom') chatService;
  @service('services/amplify') amplify;
  @service('router') router;

  engine: Bloodhound;
  filter: string = '';
  message: string = '';
  target: string = '';

  constructor() {
    super(...arguments);

    this.loadProfiles.perform();
  }

  filteredProfiles = null;

  @action
  createPm() {
    this.chatService.newChatline(this.message, this.target);
  }

  @observes('filter', 'profiles.[]')
  filteredProfilesOberserver() {
    if (this.filter === '') {
      this.set('filteredProfiles', this.profiles);
    } else {
      this.engine.search(
        this.filter,
        (d) => {
          this.set('filteredProfiles', d);
        },
        function (d) {
          console.log(d);
        }
      );
    }
  }

  @task
  *initBloodHound() {
    function customTokenizer(profile: any) {
      var nameTokens = Bloodhound.tokenizers.nonword(profile.username);
      var nameTokens2 = Bloodhound.tokenizers.nonword(profile.email);
      var nameTokens3 = Bloodhound.tokenizers.whitespace(profile.firstname);
      var ownerTokens = Bloodhound.tokenizers.whitespace(profile.lastname);

      return nameTokens
        .concat(nameTokens2)
        .concat(nameTokens3)
        .concat(ownerTokens);
    }
    let engine = new Bloodhound({
      local: this.profiles,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      datumTokenizer: customTokenizer,
    });
    yield engine.initialize();
    this.engine = engine;
  }

  @task
  *loadProfiles() {
    try {
      const variables = {
        where: {
          client: { id: this.get('amplify.currentClient.id') },
          publicprofile: true,
        },
      };
      let profiles = yield this.apollo.query(
        { query: query, variables, fetchPolicy: 'network-only' },
        'users'
      );
      console.log('profiles', profiles);
      this.set('profiles', profiles);
      this.set('filteredProfiles', profiles);
      yield this.initBloodHound.perform();
    } catch (e) {
      console.error(e);
    }
  }
}

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'eu-central-1',
  aws_cognito_identity_pool_id:
    'eu-central-1:1dc4a191-87e8-4463-99d6-c411810f7411',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_napjjuaLL',
  aws_user_pools_web_client_id: '7h13toor1di8cnpj79pevuj0tk',
  oauth: {},
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: '6',
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_cloud_logic_custom: [
    {
      name: 'AdminQueries',
      endpoint: 'https://0eo0r8ivj1.execute-api.eu-central-1.amazonaws.com/dev',
      region: 'eu-central-1',
    },
    {
      "name": "umotionsboot",
      "endpoint": "https://2fxqqnhh7i.execute-api.eu-central-1.amazonaws.com/dev",
      "region": "eu-central-1"
    }
  ],
  aws_appsync_graphqlEndpoint:
    'https://il7kl43ltnbudl5pwoy775mk6e.appsync-api.eu-central-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-central-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-vaj36jtnyjey3dwh7fbxyetzwq',
  aws_user_files_s3_bucket:
    'amplifybackend282813b726f7d448d858d69c808aa88f3114203-dev',
  aws_user_files_s3_bucket_region: 'eu-central-1',
};

export default awsmobile;

import Controller from "@ember/controller";
import { inject as service } from "@ember/service";

export default class Profil extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('services/amplify') amplify;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module "@ember/controller" {
  interface Registry {
    profil: Profil;
  }
}

define("event-app/helpers/css-name", ["exports", "event-components/helpers/css-name"], function (_exports, _cssName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _cssName.default;
    }
  });
  Object.defineProperty(_exports, "cssName", {
    enumerable: true,
    get: function () {
      return _cssName.cssName;
    }
  });
});

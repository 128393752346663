import Route from '@ember/routing/route';

export default class Map extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  beforeModel(model: any) {
    if (
      this.get('amplify.currentUser') == null &&
      this.amplify.currentTheme.config.authmodules?.map === true
    ) {
      this.transitionTo('landing');
    }
  }
  async model() {
    let model = await this.modelFor('application');
    console.log(model);
    return model;
  }
}

import Route from '@ember/routing/route';

export default class ChatProfiles extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  beforeModel(model: any) {
    if (this.get('amplify.currentUser') == null) {
      this.transitionTo('landing');
    }
  }
  async model() {}
}

import Controller from "@ember/controller";

export default class ProfilPhoto extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module "@ember/controller" {
  interface Registry {
    "profil/photo": ProfilPhoto;
  }
}

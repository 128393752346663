import Route from '@ember/routing/route';

export default class Fragebogen extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  constructor() {
    super(...arguments);
  }
  beforeModel(model: any) {
    if (this.get('amplify.currentUser') == null) {
      this.transitionTo('landing');
    }
  }
}

import Controller from '@ember/controller';
import { inject } from '@ember/service';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class Application extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  @inject isMobile;
  @inject router;
  @service('chatroom') chatService;
  @service('services/amplify') amplify;
  @service('privatechatline') pchatlineService!: any;
  constructor() {
    super(...arguments);
    this.pchatlineService.init()
    try {
      if (window.self !== window.top) {
        console.log('is in iframe');
        this.set('isMobile.any', true);
      }
    } catch (e) {
      return true;
    }
    
    if (this.get('isMobile.any')) {
      document.body.className += ' ' + 'is--mobile';
    } else {
      document.body.className += ' ' + 'is--desktop';
    }
  }

  @action
  async goToPrivateMessages() {
    if(this.router.currentRoute.name == "chat.private") {
      this.amplify.set("target", "");
      this.chatService.set("activeModel", null);
      await this.router.transitionTo("landing").promise
      this.router.transitionTo("chat.private")
    } else {
      this.router.transitionTo("chat.private")
    }
  }

  @action
  goToHome() {
    this.router.transitionTo('index');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    application: Application;
  }
}

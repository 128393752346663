define("event-app/initializers/is-mobile-service-injector", ["exports", "ismobilejs/initializers/is-mobile-service-injector"], function (_exports, _isMobileServiceInjector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _isMobileServiceInjector.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _isMobileServiceInjector.initialize;
    }
  });
});

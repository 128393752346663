import Controller from "@ember/controller";

export default class Wall extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  currentWall = false;
  null = null;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module "@ember/controller" {
  interface Registry {
    wall: Wall;
  }
}

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import RSVP from 'rsvp';
import InfoService from 'event-services/info/service';
export default class Info extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('info') infoService!: InfoService;

  constructor() {
    super(...arguments);
  }
  beforeModel(model: any) {
    if (
      this.get('amplify.currentUser') == null &&
      this.amplify.currentTheme.config.authmodules?.info === true
    ) {
      this.transitionTo('landing');
    }
  }
  async model() {
    let client = await this.modelFor('application');

    let infoItems = await this.infoService.findAll();
    infoItems =  infoItems.sort((a, b) => {
      return a.sorting - b.sorting;
    });

    return RSVP.hash({
      infoItems: infoItems,
      client: client,
    });
  }
}

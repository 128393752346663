define("event-app/helpers/map-by-question", ["exports", "event-components/helpers/map-by-question"], function (_exports, _mapByQuestion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _mapByQuestion.default;
    }
  });
  Object.defineProperty(_exports, "mapByQuestion", {
    enumerable: true,
    get: function () {
      return _mapByQuestion.mapByQuestion;
    }
  });
});

import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';

export default class Login extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('services/amplify') amplify;
  @service('router') router;

  @task
  *toLogin() {
    this.transitionToRoute('login');
  }
  @task
  *toRegister() {
    if (this.amplify.currentClient.loginmode === 'QUESTIONNAIRE') {
      this.transitionToRoute('fragebogen');
    } else {
      this.transitionToRoute('register');
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    nouser: Nouser;
  }
}

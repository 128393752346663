define("event-app/initializers/browser-update", ["exports", "ember-browser-update/initializers/browser-update"], function (_exports, _browserUpdate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _browserUpdate.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _browserUpdate.initialize;
    }
  });
});

import Component from '@ember/component';
// @ts-ignore: Ignore import of compiled template
import layout from './template';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { computed } from '@ember/object';
import { observes } from '@ember-decorators/object';
import { getOwner } from '@ember/application';
import { isEmpty } from '@ember/utils';
import AmplifyService from '../../../lib/event-components/addon/amplify/service';

function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
}

// returns black or white depending on the background color given
function getTextColorBasedOnBackground(colorHex: string): string {
  console.log(colorHex);
  
  if(!colorHex) return "#fff";
  if (colorHex.includes('#')) colorHex = colorHex.slice(1);

  const rbg = hexToRgb(colorHex);
  if (rbg == null) return '#fff';
  // https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
  console.log(rbg.r * 0.299 + rbg.g * 0.587 + rbg.b * 0.114 > 186 ? '#000' : '#fff');
  
  return rbg.r * 0.299 + rbg.g * 0.587 + rbg.b * 0.114 > 186 ? '#000' : '#fff';
}

export default class VoteChartInternal extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  layout = layout;

  @service() router!: any;
  @service('services/amplify') amplify!: AmplifyService;
  @service('vote') voteService;
  @service('theme') themeService;

  @service('cookies') cookies;

  @observes('currentVote.id')
  onCurrentVoteChanged() {
    this.set('currentVote', this.currentVote.id);
  }

  constructor() {
    super(...arguments);
  }
  didInsertElement() {
    this.amplify.on('ws:updateVote', this, 'onVotesChanged');
  }

  backgroundUrl  = "";
  @observes("model.vote","model.vote.background")
  async setBackgroundUrl() {
    if (isEmpty(this.model.vote) || isEmpty(this.model.vote.background)) {
      console.log("empty map");
      return;
    }
    try {
      let cachedUrl = this.get("amplify.Cache").getItem(this.model.vote.background.key);
      if (cachedUrl !== null) {

        this.set("backgroundUrl", cachedUrl.split("?")[0]);
      }
      let url = await this.get("amplify")
        .Storage.get(this.model.vote.background.key, {
          expires: 86400,
          level: "public",
        })
        .then((url: any) => {
          if (url.includes("?") && this.removeCache) {
            url = url.split("?")[0];
          }
          this.get("amplify.Cache").setItem(this.model.vote.background.key, url);
          return url;
        });

      this.set("backgroundUrl", url);
    } catch (e) {
      console.error(e);
    }   
  }

  @observes('voteService.models.[]')
  async loadModel() {
    let vote, votes;

    const client = this.amplify.get('currentClient');

    votes = this.get('voteService.models');
    vote = votes.find((v: any) => v.id == this.voteId);
    this.voteService.set('activeModel', vote)
    this.set('model', {
      vote,
      votes,
      user: client.user,
      client: client,
    });
  }

  @computed('voteService.activeModel.charttype', "themeService.activeThemeConfig.backgroundMain")
  get chartoptions() {
    console.log("voteService.activeModel.charttype", this.voteService.activeModel.charttype);
    
    
    return {
      animation: {
        duration: 0,
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 25,
          bottom: 0,
        },
      },
      plugins: {
        labels: [
          {
            render: 'label',
            position: 'outside',
            fontColor: getTextColorBasedOnBackground(this.themeService.get("activeThemeConfig.backgroundMain")),
            fontSize: this.get('voteService.activeModel.fontsize')
              ? this.get('voteService.activeModel.fontsize').replace('px', '')
              : 14,
            fontStyle: 'bold',
          },
          {
            fontColor: getTextColorBasedOnBackground(this.themeService.get("activeThemeConfig.backgroundMain")),
            render: function (args) {              
              return ``;
            },
            fontSize: this.get('voteService.activeModel.fontsize')
              ? this.get('voteService.activeModel.fontsize').replace('px', '')
              : 14,
            fontStyle: 'bold',
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            //get the concerned dataset
            var dataset = data.datasets[tooltipItem.datasetIndex];
            //calculate the total of this data set
            var total = dataset.data.reduce(function (
              previousValue,
              currentValue,
              currentIndex,
              array
            ) {
              return previousValue + currentValue;
            });
            //get the current items value
            var currentValue = dataset.data[tooltipItem.index];
            //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
            var percentage = Math.floor((currentValue / total) * 100 + 0.5);

            return percentage + '%';
          },
        },
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              color: 'rgba(0, 0, 0, 0)',
              drawBorder: false,
              display: this.voteService.activeModel.charttype != "horizontalBar",
            },
            ticks: {
              beginAtZero: true,
              display: true,
              fontColor: getTextColorBasedOnBackground(this.themeService.get("activeThemeConfig.backgroundMain")),
              userCallback: (label, index, labels) => {
                // when the floored value is the same as the value we have a whole number
                if(this.voteService.activeModel.charttype == "horizontalBar") {
                  return "";
                }
                if (Math.floor(label) === label) {
                  return label;
                }
              },
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              display: this.voteService.activeModel.charttype == "horizontalBar",
              fontColor: getTextColorBasedOnBackground(this.themeService.get("activeThemeConfig.backgroundMain")),
              beginAtZero: true,
              fontSize: 20,
              bold: true
            },
            gridLines: {
              color: 'rgba(0, 0, 0, 0)',
              drawBorder: false,
              display: false,
            },
          },
        ],
      },
    };
  }
  @task()
  *goBack(voteId) {
    this.get('router').transitionTo('vote', voteId);
  }
  @task()
  *reload(voteId) {
    let route = getOwner(this).lookup(`route:votechart`);
    return route.refresh();
  }

  @computed('currentAnswer', 'voteService.activeModel.votes.[]', 'voteService.models.[]')
  get data() {
    try {
      if (!this.get('voteService.activeModel.votes')) {
        return null
      }

      function getSum(total, num) {
        return total + Math.round(num);
      }
      const data = this.get('voteService.activeModel.votes').reduce((data, vote) => {
        let index = this.get('voteService.activeModel.values').indexOf(vote.vote);
        if (data[index] === undefined) {
          data[index] = 0;
        }
        data[index] = data[index] + 1;
        return data;
      }, []);
      return {
        labels: this.get('voteService.activeModel.values').map((v, index) => {
          let label = this.get(`voteService.activeModel.shortvalues.${index}`) || v;
          if (label === undefined || label === null || label === "") {
            label = v;
          }
          let all = data.reduce(getSum, 0);
          if (data[index] === undefined) data[index] = 0;
          return `${label} (${Math.round((data[index] / all) * 100 * 100) / 100}%)`;
        }),
        datasets: [
          {
            data: data,
            borderSkipped: false,
            borderColor: 'rgba(0, 0, 0, 0)',
            backgroundColor: this.get('voteService.activeModel.colors').map((c: string) => {
              if (c.includes('#')) {
                return `${c}`;
              } else {
                return `#${c}`;
              }
            }),
          },
        ],
      };
    } catch (e) {
      console.log(e);
    }
  }

  didReceiveAttrs() {
    this.set('voteid', this.get('cookies').read(`vote-${this.votescope}`));
    this.loadModel();
  }

  @computed('voteService.activeModel.votes.[]', 'currentVote.vote.votes.[]')
  get currentAnswers() {
    if (this.get('voteService.activeModel.votes') == null) {
      return false;
    }
    return this.get('voteService.activeModel.votes').filterBy(
      'user',
      this.amplify.currentUser.id
    );
  }

  willDestroy() {
    this.get('voteService').off('vote:changed');
  }
}

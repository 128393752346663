import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class VoteChart extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  constructor() {
    super(...arguments);
  }

  async model(params) {
    return params.vote_id;
  }
}

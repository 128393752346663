import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import { action, setProperties } from '@ember/object';
import QuestionService from 'event-components/question/service';
import AnswerService from 'event-components/answer/service';

export default class Fragebogen extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('question') questionService!: QuestionService;
  @service('answer') answerService!: AnswerService;

  @service('services/amplify') amplify;

  headTags?: object[];

  constructor() {
    super(...arguments);
  }
  @action
  willTransition(transition) {
    /*if(document.getElementById('anmeldeform').checkValidity() === false) {
      transition.abort();
      this.get('notifications').info('Bitte füllen Sie die rot umrandeten Felder aus.');
      this.controller.set('isInvalid', true);
    }*/
  }

  async model() {
    const client = await this.modelFor('application');
    /*
    let questions = await this.questionService.findAll();
    questions.forEach((q) => {
      this.questionService.delete(q);
    });
    */
    /*let ques = this.questionService.createChangeset({
      question: 'Möchsten sie Notis erhalten?',
      values: ['ja', 'nein'],
      group: 'default',
      sorting: 1,
      type: 'RADIO',
      isMultiselectQuestion: false,
      multiselectlimit: 1,
      isProfileQuestion: true,
      isProfileFilter: true,
      profileField: 'notifications',
      profileFieldType: 'BOOLEAN',
      isParticipationQuestion: false,
      isAnswerBasedCounting: false,
      maxParticipants: 0,
    });

    this.questionService.create(ques);*/
    let answers = await this.answerService.findAll();
    /*answers.forEach((a) => {
      this.answerService.delete(a);
    });*/
    return {
      user: client.user,
      client: client,
    };
  }
}

import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import QuestionService from 'event-services/question/service';
import AnswerService from 'event-services/answer/service';
export default class Fragebogen extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('question') questionService!: QuestionService;
  @service('answer') answerService!: AnswerService;
  isInvalid: boolean = false;

  @task
  *timeout() {
    yield timeout(1000);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    Fragebogen: Fragebogen;
  }
}

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class Index extends Route.extend({}) {

  @service('services/amplify') amplify;

  beforeModel() {
    if (
      !this.get('amplify.isAuthenticated') &&
      this.amplify.currentClient.loginmode !== 'ANONYM'
    ) {
      this.transitionTo('landing');
      return;
    }
  }
}

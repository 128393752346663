import Route from '@ember/routing/route';

export default class Streams extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  async model() {

    return this.get('amplify.currentTheme.config.streamConfig');
  }
}

import Service from '@ember/service';
import Evented from '@ember/object/evented';

export default class Eventbus extends Service.extend(Evented) {
  // normal class body definition here
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'eventbus': Eventbus;
  }
}

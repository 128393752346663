import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class VoteChart extends Route.extend({
  // anything which *must* be merged to prototype here
}) {

  @service('vote') voteService;
  constructor() {
    super(...arguments);
  }

  async model(params) {
    await this.voteService.findAll();
    let vote = this.voteService.models.filterBy('id', params.vote_id);
    if (vote.firstObject) {
      this.voteService.set('activeModel',vote.firstObject)
    }
    return params.vote_id;
  }
}

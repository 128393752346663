import Route from '@ember/routing/route';

export default class Wall extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  beforeModel(model: any) {
    if (
      this.get('amplify.currentUser') == null &&
      this.amplify.currentTheme.config.authmodules?.posts === true
    ) {
      this.transitionTo('landing');
    }
  }
}

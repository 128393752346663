import Controller from "@ember/controller";
import { inject as service } from "@ember/service";
import { task } from "ember-concurrency-decorators";
import { action, computed } from "@ember/object";
import { observes } from "@ember-decorators/object";
import Evented from "@ember/object/evented";
import { getOwner } from "@ember/application";
export default class VoteChart extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  @service() router;

  @observes("currentVote.id")
  onCurrentVoteChanged() {
    this.set("currentVote", this.currentVote.id);
  }

  @service("vote") voteService;

  @computed("model.vote.charttype")
  get chartoptions() {
    return {
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            //get the concerned dataset
            var dataset = data.datasets[tooltipItem.datasetIndex];
            //calculate the total of this data set
            var total = dataset.data.reduce(function (
              previousValue,
              currentValue,
              currentIndex,
              array
            ) {
              return previousValue + currentValue;
            });
            //get the current items value
            var currentValue = dataset.data[tooltipItem.index];
            //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
            var percentage = Math.floor((currentValue / total) * 100 + 0.5);

            return percentage + "%";
          },
        },
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              color: "rgba(0, 0, 0, 0)",
              drawBorder: false,
              display: false,
            },
            ticks: {
              beginAtZero: true,
              userCallback: function (label, index, labels) {
                // when the floored value is the same as the value we have a whole number

                if (Math.floor(label) === label) {
                  return label;
                }
              },
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              display: this.model.vote.charttype === "pie" ? false : true,
            },
            gridLines: {
              color: "rgba(0, 0, 0, 0)",
              drawBorder: false,
              display: false,
            },
          },
        ],
      },
    };
  }
  @task()
  *goBack(voteId) {
    this.get("router").transitionTo("vote", voteId);
  }
  @task()
  *reload(voteId) {
    let route = getOwner(this).lookup(`route:votechart`);
    return route.refresh();
  }

  @computed("currentAnswer", "model.vote.votes.[]", "voteService.votes.[]")
  get data() {
    try {
      if (!this.get("model.vote.votes")) {
        return null;
      }

      function getSum(total, num) {
        return total + Math.round(num);
      }
      const data = this.get("model.vote.votes").reduce((data, vote) => {
        let index = this.get("model.vote.values").indexOf(vote.vote);
        if (data[index] === undefined) {
          data[index] = 0;
        }
        data[index] = data[index] + 1;
        return data;
      }, []);
      return {
        labels: this.get("model.vote.values").map((v, index) => {
          let all = data.reduce(getSum, 0);
          if (data[index] === undefined) data[index] = 0;
          return `${v} (${Math.round((data[index] / all) * 100 * 100) / 100}%)`;
        }),
        datasets: [
          {
            label: "test",
            data: data,
            borderSkipped: false,
            borderColor: "rgba(0, 0, 0, 0)",
            backgroundColor: this.get("model.vote.colors").map((c: string) => {
              if (c.includes("#")) {
                return `${c}`;
              } else {
                return `#${c}`;
              }
            }),
          },
        ],
      };
    } catch (e) {
      console.error(e);
    }
  }

  didReceiveAttrs() {
    this.set("voteid", this.get("cookies").read(`vote-${this.votescope}`));
  }

  @computed("model.vote.votes.[]", "currentVote.vote.votes.[]")
  get currentAnswers() {
    if (this.get("model.vote.votes") == null) {
      return false;
    }
    return this.get("model.vote.votes").filterBy(
      "user",
      this.amplify.currentUser.id
    );
  }

  willDestroy() {
    this.get("voteService").off("vote:changed");
  }

  onVotesChanged(vote: any) {
    console.log(...arguments);
    if (vote.id === this.currentVote.id) {
      this.set("currentVote", vote);
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module "@ember/controller" {
  interface Registry {
    vote: Vote;
  }
}

import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import AnswerService from '@netzreich/event-services/answer/service';
import QuestionService from '@netzreich/event-services/question/service';

export default class AclQuestion extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  tagName = '';

  
  @service('question') questionService!: QuestionService;

  answers!: any[];

  questionAnswer: any ;

  whitelist: {
    questionId: string,
    valueIndex: string
  }[] | undefined;

  @computed(
    'questionService.models.[]',
    'answers.[]',
    'answers.@each.values',
    'whitelist'
  )
  get show() {
    if (!this.whitelist || !Array.isArray(this.whitelist) || this.whitelist.length === 0 || !this.answers) {
  
      return true;
    }
  
    let show = false;
    this.whitelist.forEach((w)=>{
      let questionAnswer =  this.answers.find(a=>{
        return a.get('question.id') === w.questionId
      });
      if (!questionAnswer) {
        show = false;     
        return;
      }
      this.set('questionAnswer', questionAnswer)
      if (questionAnswer.get('pendingData').values.includes(w.valueIndex.toString())) {
        show =  true;
      }
    })

    return show;
  }
}

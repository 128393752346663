import { helper } from '@ember/component/helper';
import { isEmpty } from '@ember/utils';
import AmplifyService from '../../lib/event-components/addon/amplify/service';

export function awsimage(params: any/*, hash*/) {
  const file = params[0];
  const amplify: AmplifyService = params[1];

  if ( isEmpty(file)) {
    return "";
  }
  try {
    let cachedUrl = amplify.Cache.getItem(file.key);
    if (cachedUrl !== null) {
      return cachedUrl.split("?")[0];
    }
    let url = amplify
      .Storage.get(file.key, {
        expires: 86400,
        level: "public",
      })
      .then((url: any) => {
        if (url.includes("?")) {
          url = url.split("?")[0];
        }
        amplify.Cache.setItem(file.key, url);
        return url;
      });

    return url;
  } catch (e) {
    console.error(e);
  }
  return ""
}

export default helper(awsimage);

import { helper } from '@ember/component/helper';

export function specialCounter(params/*, hash*/) {
  const limit = params[0];
  const value = params[1];
  const answers = params[2];
  if (answers === undefined) {
    return false;
  }
  const filteredAnswers = answers.filter(item=>{
    return item.value.split(",").includes(value.toString());
  });
  return limit - filteredAnswers.length;
}

export default helper(specialCounter);

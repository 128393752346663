import Component from '@ember/component';
import { computed } from '@ember/object';

export default class AclGroups extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  tagName = '';

  groups:  string[]|null = null;

  usergroups!:  string[];

  @computed('groups', 'usergroups')
  get show() {
    
    if (this.groups == null) {
      return true;
    }
    if (this.groups.length === 0) {
      return true;
    }
    if (this.groups.length > 0 && this.usergroups == null) {
      return false;
    } 
    return this.groups.some((element) => {
      return this.usergroups.includes(element);
    });
  }
}

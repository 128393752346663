import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';


export default class Gallery extends Route.extend({
  // anything which *must* be merged to prototype here
}) {

  model(params) {
      console.log(params)
      return params.id
  }

}
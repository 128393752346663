import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';

import { inject as service } from '@ember/service';
import move from 'ember-animated/motions/move';
import { easeOut, easeIn } from 'ember-animated/easings/cosine';

export default class MyApp extends Component.extend({

}) {

  @service("repository/user") userService: any;

  transition = function * (context) {
    
    let { insertedSprites, keptSprites, removedSprites } = context;

    insertedSprites.forEach(sprite => {
      sprite.applyStyles({ 'z-index': 1 });
      sprite.startAtPixel({ x: window.innerWidth });
      move(sprite, { easing: easeOut });
    });

    keptSprites.forEach(move);

    removedSprites.forEach(sprite => {
      sprite.applyStyles({ 'z-index': 1 });
      sprite.endAtPixel({ x: window.innerWidth * 0.8 });
      move(sprite, { easing: easeIn });
    });
  };

  status: string = '';

  newUser: object = {};

  users: any = [];

  lastUsers;

  @computed('users.[]') 
  get usersChanges() {



    this.set('lastUsers', this.users)
    return true;
  }

  constructor() {
    super(...arguments);
    this.loadUser.perform();
  }

  @task
  *test(test: string) {

    yield timeout(300);
  }
  @task
  *deleteUser(userId: string) {
    yield timeout(300);
    let user = yield this.userService.delete(userId);

  }
  @task
  *updateUser(user: string) {
    yield timeout(300);
    let updatedUser = yield this.userService.update(user);

  }

  @task
  *loadUser() {

    let users = yield this.userService.findAll();
    this.set('users', users);

  }

  @task
  *createUser(userData: object): any {
    this.set('status', "Gimme one second...");
    yield timeout(300);
    yield this.userService.create(userData);
    this.set('status', "Done");
  }

  @action
  findAllUser() {
    this.loadUser.perform()
  }

  @action
  startTask() {
    this.createUser.perform({name: "test"})
  }
};

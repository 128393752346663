import { helper } from '@ember/component/helper';

export function greeting(params/*, hash*/) {

  let greeting =  params[0];
  let { email, firstname, lastname, var1 } = params[1];
  console.log(params, email, firstname, lastname, var1)
  console.log(typeof email)
  let message =  greeting;
  if (typeof email !== 'undefined') {
    message = message.replace('{email}', email);
  } else {
    message = message.replace('{email}', '');
  }
  if (typeof firstname !== 'undefined') {
    message = message.replace('{firstname}', firstname);
  } else {
    message =  message.replace(' {firstname}', '');
  }
  if (typeof lastname !== 'undefined') {
    message = message.replace('{lastname}', lastname);
  } else {
    message = message.replace(' {lastname}', '');
  }
  if (typeof var1 !== 'undefined') {
    message = message.replace('{var1}', var1);
  } else {
    message = message.replace('{var1}', '');
  }
  return message;
}

export default helper(greeting);

import Application from "@ember/application";
import browserUpdate from "browser-update";

export function initialize(application: Application): void {
  if (application.hasRegistration("config:environment")) {
    const config = application.resolveRegistration("config:environment");

    browserUpdate(config["browserUpdate"]);
  } else {
  }
}

export default {
  initialize,
};

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import gql from 'graphql-tag';
import { isEmpty } from '@ember/utils';
import { getOwner } from '@ember/application';

const query = gql`
  query users($where: UserWhereInput!) {
    users(where: $where) {
      id
      invitecode
      email
    }
  }
`;

export default class Init extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('services/amplify') amplify;

  constructor() {
    super(...arguments);
  }
  serialize(model, params) {
    // If we got here without an ID (and therefore without a model)
    // Ensure that we leave the route param in the URL blank (not 'undefined')

    if (!model) {
      return { user_name: '' };
    }

    // Otherwise, let Ember handle it as usual
    return this._super.apply(this, arguments);
  }
  async model(params) {
    try {
      const client = await this.modelFor('application');
      let variables = {
        where: {
          invitecode: params.user_id,
          client: {
            id: client.id,
          },
        },
      };
      variables.where.username = params.user_name;
      console.log('#1');
      let users = await this.apollo.query(
        { query, variables, fetchPolicy: 'network-only' },
        'users'
      );
      console.log('#2');

      if (!isEmpty(users)) {
        window.localStorage.setItem('user', users.firstObject.id);

        console.log('#3');
      } else {
        console.log('#4');
        if (client.loginmode === 'CODE') {
          console.log('#7');
          this.transitionTo('login', {
            queryParams: {
              error:
                'Dieser Benutzer ist uns nicht bekannt, oder das Passwort ist falsch',
            },
          });
        }
        if (client.loginmode === 'DEFAULT') {
          console.log('#8');
          this.transitionTo('landing');
        }
        console.log('#5');

        return;
      }

      let route = getOwner(this).lookup(`route:application`);
      route.refresh();
      this.transitionTo('application');
      return;
    } catch (e) {
      console.error(e);
    }
  }
}

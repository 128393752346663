define("event-app/components/searchable-select-option", ["exports", "ember-searchable-select/components/searchable-select-option"], function (_exports, _searchableSelectOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _searchableSelectOption.default;
    }
  });
});

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class Login extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('services/amplify') amplify;
  beforeModel() {
    localStorage.setItem('firstvisit', '1');

    // Transition away from this route if anonymous login is enabled
    if(this.amplify.currentClient.loginmode === 'ANONYM') {
      this.transitionTo('index');
    }
  }
}
